import React from "react";
import { Navbar } from "../../components";
import { base } from "../blog/imports";
import Footer from "../footer/Footer";
import './cursos.css';

function Formaciondebase() {
  return (
    <div className="main" id="top">
      <Navbar></Navbar>
      <div className="container-header" id="base">
        <h1 className="gradient__text">Curso de Formación de Base</h1>
      </div>
      <div className="cont-img"> <img src={base} className='course-img' alt="" /></div>
      <div className="container-body">
        <p>
          Fecha: 7, 8, 9, 12, 13 y 14 de Agosto, a través de la plataforma ZOOM <br /> <br /><br />

          Una mente bien orientada y enfocada te posibilita la creación de una vida íntegra y feliz, ya que nuestra vida es consecuencia de la calidad de nuestros pensamientos, emociones, y de las acciones y decisiones que   tomamos.

          El Método Silva reúne un conjunto de técnicas que desarrollan las capacidades innatas, pero sin ejercitar, que tiene el ser humano. Cualquiera sea el punto de partida del alumno, su desarrollo, su nivel intelectual, o su edad, obtendrá al finalizar el curso un importante incremento en el uso de dichas capacidades y podrá aplicarlas para ayudarse a resolver los problemas de la vida cotidiana, mejorando así en gran medida su calidad de vida.</p>
        <div className="container-header">
          <p className="gradient__text">Programa</p>
        </div>
        <p>Con este curso, aprenderás a manejar la ANSIEDAD y el ESTRÉS, superar MIEDO, cambiar CREENCIAS limitantes, controlar HÁBITOS negativos, aprender a manejar mejor tus EMOCIONES, REACCIONES y ACTITUDES, mejorar RELACIONES (familiares, laborales y sociales), mejorar y/o mantener tu SALUD, calmar DOLORES, superar el INSOMNIO y dormir mejor, desarrollar el SUEÑO CREATIVO para resolver problemas, desarrollar la CREATIVIDAD y la INTUICIÓN, mejorar la CONCENTRACIÓN, memoria y el aprendizaje. Ademas de dominar las técnicas de ESTUDIO y establecer METAS y objetivos y desarrollar la capacidad de acción para alcanzarlos</p>
        <a href="https://api.whatsapp.com/send?phone=541134523591"><button className='button-header'>Contactate con nosotros</button></a>
      </div>
    </div>
  );
}

export default Formaciondebase;